/**
 * Wrapper around localStorage, mainly to abstract away error handling
 */ 
function getItem(key: string): any | null {
  if (key === undefined) {
    return null;
  }
  
  const raw = localStorage.getItem(key);
  if (raw === undefined || raw === null) {
    return null;
  }

  return JSON.parse(raw);
}

function setItem(key: string, value: unknown) {
  localStorage.setItem(key, JSON.stringify(value));
}

export const Stash = {
  getItem,
  setItem,
};
