import { derived, writable } from "svelte/store";
import { loadState, saveState } from "../core/api";
import type { Input, Spell } from "@character-sheet/types";
import { Stash } from "../core/stash";
import { characterClass, characterSubclass } from "./classes";
import { characterRace } from "./races";
import { abilities } from "./abilities";
import { selectedArmorName, selectedWeaponName } from "./items";

export const rules = writable<string>(Stash.getItem('rules'));
export const preparedSpells = writable<{ [name: string]: Spell | null }>({});

export const spellSlotsUsed = (() => {
  
  const { subscribe, set, update } = writable<{ [level: number]: number }>(Stash.getItem('spellSlotsUsed') || {});

  const setSpellSlotUsed = (level: number, value: number) => {
    update((ssu) => ({
      ...ssu,
      [level]: value,
    }));
  };
  
  return { subscribe, set, update, setSpellSlotUsed };
})();

export interface State {
  rules: string;
  input: Input;
  preparedSpells: { [name: string]: Spell | null };
  spellSlotsUsed: { [level: number]: number };
  resources: Resources;
}

export interface Resources {
  [resource: string]: boolean | number;
}

export const resources = writable<Resources>(Stash.getItem('resources'));

export const level = writable<number>();
export const currentHitDice = writable<number>();
export const currentHP = writable<number>();
export const currentTempHP = writable<number>();

export const userSkillProfs = (() => {
  const { subscribe, update, set } = writable<string[]>([]);

  const toggle = (proficiency: string) => {
    update((oldArr) => {
      const index = oldArr.indexOf(proficiency);
      // Add it when not found
      if (index === -1) {
        return [...oldArr, proficiency];
      } else {
        // Remove it when found
        oldArr.splice(index, 1);
        return oldArr;
      }
    });
  };
  
  return {
    subscribe,
    set,
    toggle,
  };
})();

export const userSavingThrows = (() => {
  const { subscribe, update, set } = writable<string[]>([]);
  return {
    subscribe,
    set,
    toggle: (proficiency: string) => {
      update((oldArr) => {
        const index = oldArr.indexOf(proficiency);
        // Add it when not found
        if (index === -1) {
          return [...oldArr, proficiency];
        } else {
        // Remove it when found
          oldArr.splice(index, 1);
          return oldArr;
        }
      });
    },
  };
})();

function collectionStore() {
  const { subscribe, update, set } = writable<{ [key: string]: number | undefined }>({});
  return {
    subscribe,
    set,
    add: (key: string, bonus: number) => update((old) => ({ ...old, [key]: bonus })),
    remove: (key: string) => update((old) => {
      delete old[key];
      return old;
    }),
    toggle: (key: string, bonus: number) => {
      update((old) => {
        if(old[key]) {
          delete old[key];
          return old;
        } else {
          return ({ ...old, [key]: bonus });
        }
      })
    },
  }
}

export const acBonuses = collectionStore();
export const meleeAttackBonuses = collectionStore();;
export const weaponDamageBonuses = collectionStore();;

export const input = derived([
  level,
  abilities.str,
  abilities.dex,
  abilities.con,
  abilities.int,
  abilities.wis,
  abilities.cha,
  selectedArmorName,
  selectedWeaponName,
  characterRace,
  characterClass,
  characterSubclass,
  currentHitDice,
  currentHP,
  currentTempHP,
  userSkillProfs,
  acBonuses,
  meleeAttackBonuses,
  weaponDamageBonuses,
], ([
  $level,
  $str,
  $dex,
  $con,
  $int,
  $wis,
  $cha,
  $selectedArmorName,
  $selectedWeaponName,
  $characterRace,
  $characterClass,
  $characterSubclass,
  $currentHitDice,
  $currentHP,
  $currentTempHP,
  $userSkillProfs,
  $acBonuses,
  $meleeAttackBonuses,
  $weaponDamageBonuses,
]) => {
    return {
      level: $level,
      str: $str,
      dex: $dex,
      con: $con,
      int: $int,
      wis: $wis,
      cha: $cha,
      str_st: false,
      dex_st: false,
      con_st: false,
      int_st: false,
      wis_st: false,
      cha_st: false,
      user_skill_profs: $userSkillProfs,
      race: $characterRace,
      class: $characterClass,
      subclass: $characterSubclass,
      armor: $selectedArmorName ?? '',
      weapon: $selectedWeaponName ?? '',
      current_hit_dice: $currentHitDice,
      current_hp: $currentHP,
      current_temp_hp: $currentTempHP,
      ac_bonuses: $acBonuses,
      melee_attack_bonuses: $meleeAttackBonuses,
      weapon_damage_bonuses: $weaponDamageBonuses,
    };
  });

export const state = derived([rules, input, preparedSpells, spellSlotsUsed, resources], ([$rules, $input, $preparedSpells, $spellSlotsUsed, $resources]) => {
  const state: State = {
    rules: $rules,
    input: $input,
    preparedSpells: $preparedSpells,
    spellSlotsUsed: $spellSlotsUsed,
    resources: $resources,
  };

  // Check if every field is set
  if(Object.values(state).every((v) => v)) {
    Stash.setItem("rules", $rules);
    Stash.setItem("input", $input);
    Stash.setItem("preparedSpells", $preparedSpells);
    Stash.setItem("spellSlotsUsed", $spellSlotsUsed);
    Stash.setItem("resources", $resources);
  }
  
  return { state };
});


// We debounce the state to prevent excessive PUT requests to the backend.
// It also gives the stores a chanche to initialize before trying to
// persist the input at startup. Note that the latter is quite the hack.
let stateSaveDebounceTimer: NodeJS.Timeout;
state.subscribe((newState) => {
  clearTimeout(stateSaveDebounceTimer);
  stateSaveDebounceTimer = setTimeout(() => {
    saveState(newState.state);
  }, 750);
});

loadState().then((newState) => {
  level.set(newState.input.level);

  abilities.str.set(newState.input.str);
  abilities.dex.set(newState.input.dex),
  abilities.con.set(newState.input.con),
  abilities.int.set(newState.input.int),
  abilities.wis.set(newState.input.wis),
  abilities.cha.set(newState.input.cha),

  characterRace.set(newState.input.race);
  characterClass.set(newState.input.class);
  characterSubclass.set(newState.input.subclass);

  userSkillProfs.set(newState.input.user_skill_profs);
  selectedWeaponName.set(newState.input.weapon);
  selectedArmorName.set(newState.input.armor);

  level.set(newState.input.level);
  currentHitDice.set(newState.input.current_hit_dice);
  currentHP.set(newState.input.current_hp);
  currentTempHP.set(newState.input.current_temp_hp);

  acBonuses.set(newState.input.ac_bonuses);
  meleeAttackBonuses.set(newState.input.melee_attack_bonuses);
  weaponDamageBonuses.set(newState.input.weapon_damage_bonuses);
  
  rules.set(newState.rules);
  Stash.setItem("rules", newState.rules);
  preparedSpells.set(newState.preparedSpells);
  Stash.setItem("preparedSpells", newState.preparedSpells);
  spellSlotsUsed.set(newState.spellSlotsUsed);
  Stash.setItem("spellSlotsUsed", newState.spellSlotsUsed);
  resources.set(newState.resources);
  Stash.setItem("resources", newState.resources);
});
