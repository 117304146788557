<script lang="ts">
import { onMount } from 'svelte';
import { currentPage, Page } from './core/router';
import { whoami } from './core/api';
import Combat from './pages/Combat.svelte';
import Prepared from './pages/Prepared.svelte';
import Settings from './pages/Settings.svelte';
import Sheet from './pages/Sheet.svelte';
import Spells from './pages/Spells.svelte';
import NavButton from './NavButton.svelte';
onMount(() => {
  whoami();
});
</script>

<nav>
  <NavButton page={Page.Sheet}>Sheet</NavButton>
  <NavButton page={Page.Spells}>Spells</NavButton>
  <NavButton page={Page.Prepared}>Prepared</NavButton>
  <NavButton page={Page.Combat}>Combat</NavButton>
  <NavButton page={Page.Settings}>Settings</NavButton>
</nav>
<main>
  {#if $currentPage === Page.Sheet}
    <Sheet />
  {:else if $currentPage === Page.Spells}
    <Spells />
  {:else if $currentPage === Page.Prepared}
    <Prepared />
  {:else if $currentPage === Page.Combat}
    <Combat />
  {:else if $currentPage === Page.Settings}
    <Settings />
  {/if}
</main>
<style>
main {
  margin-bottom: 5em;
}
nav {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
