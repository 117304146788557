import type { Spell } from "@character-sheet/types";
import type { State } from "../stores/stores";

export const API_URL =
  import.meta.env.MODE === "development" ? "http://localhost:3000" : "";

export async function whoami(): Promise<any> {
  await get(`${API_URL}/whoami`);
}

export async function loadState(): Promise<State> {
  const response = await get(`${API_URL}/character`);
  const json = await response.json();
  return json.data.state;
}

export function saveState(state: State) {
  put(`${API_URL}/character`, { state }).catch((e) => console.error(e));
}

export async function loadSpells(
  characterClass: string | null,
  subclass: string | null,
  level: number | null,
): Promise<Spell[]> {
  let url = `${API_URL}/spells`;
  let params = new URLSearchParams();
  if (characterClass) {
    params.append('class', characterClass);
  }

  if (subclass) {
    params.append('subclass', subclass);
  }
  
  if (level) {
    params.append('level', level.toString());
  }

  if (params.size > 0) {
    url += '?' + params.toString();
  }
  
  const req = await get(url);
  const response = await req.json();
  return response.data;
}

export async function get(url: string): Promise<Response> {
  return fetch(url.toLowerCase(), {
    credentials: "include",
  });
}

function put(url: string, data: Object): Promise<Response> {
  return fetch(url.toLowerCase(), {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    credentials: "include",
  });
}
